<template>
  <div id="manager-detail" class="manager-detail">
    <template v-if="!loading && list.length > 0">
      <div class="info">
        <div class="header">
          <div class="name">
            <img :src="list[0].managerHeadImgURL" class="avatar">
            {{ list[0].managerUsername }}
          </div>
          <div class="des">
            <span class="num">{{ list.length }}</span>
            款商品符合您
          </div>
        </div>
        <div class="list">
          <van-swipe ref="swipe" class="my-swipe" indicator-color="#999999" @change="swiperChange">
            <template v-for="item in list">
              <van-swipe-item :key="item.id">
                <div class="swiper-item">
                  <div class="top">
                    <div class="header">
                      <div class="logo">
                        <img :src="item.funderLogo" class="img">
                      </div>
                      <div class="des">
                        <div class="name">{{ item.replaceName }}</div>
                        <div class="type">
                          <span class="it">{{ item.creditTypeName }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="group">
                        <div class="name">额度范围(万)</div>
                        <div class="des">
                          <span class="num">{{ item.loanFrom }}-{{ item.loanTo }}</span>
                        </div>
                      </div>
                      <div class="group">
                        <div class="name">月利率(%)</div>
                        <div class="des">
                          <span class="num">{{ item.interestRateFrom }}-{{ item.interestRateTo }}</span>
                        </div>
                      </div>
                      <div class="group">
                        <div class="name">贷款期限(月)</div>
                        <div class="des">
                          <span class="num">{{ item.creditLimitFrom }}-{{ item.creditLimitTo }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="bottom">
                    <div class="group">
                      <div class="title">还款方式</div>
                      <div class="cont">{{ item.paymentTypeList && item.paymentTypeList.join('，') }}</div>
                    </div>
                    <div class="group">
                      <div class="title">利率说明</div>
                      <div class="cont table">
                        <div class="tr">
                          <div class="td">贷款期限(月)</div>
                          <div class="td" style="color: #FF7700">{{ item.creditLimitFrom }}-{{ item.creditLimitTo }}</div>
                        </div>
                        <div class="tr">
                          <div class="td">月利率(%)</div>
                          <div class="td" style="color: #FF7700">{{ item.interestRateFrom }}-{{ item.interestRateTo }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </van-swipe-item>
            </template>
          </van-swipe>
        </div>
        <div class="notice">
          <div class="head">须知></div>
          <div class="cont">
            <div v-if="serviceFeeRateExplain" class="p">1、{{ serviceFeeRateExplain }}</div>
            <div v-if="extraCharge" class="p">2、{{ extraCharge }}</div>
          </div>
        </div>
      </div>
      <van-tabs ref="tabs" v-model="tabActive" color="#FF7226" title-active-color="#FF7226" sticky scrollspy :class="{ 'tab-show': isFixed }" @scroll="tabScroll">
        <van-tab title="服务保障">
          <div class="service">
            <div class="header">服务保障</div>
            <div class="content">
              <div class="list">
                <div v-for="(item, index) in serviceList" :key="item.id" class="item" :class="index == serviceActive ? 'active' : ''" @click="serviceChange(index)">
                  <div class="icon">
                    <img v-if="index == 0" src="@/assets/match/match_01.png" class="img">
                    <img v-if="index == 1" src="@/assets/match/match_02.png" class="img">
                    <img v-if="index == 2" src="@/assets/match/match_03.png" class="img">
                    <img v-if="index == 3" src="@/assets/match/match_04.png" class="img">
                  </div>
                  <div class="title">{{ item.title }}</div>
                </div>
              </div>
              <div class="notice">{{ serviceNotice[serviceActive] }}</div>
            </div>
          </div>
        </van-tab>
        <van-tab title="贷款计算器">
          <div class="countor">
            <div class="header">贷款计算器</div>
            <div class="group">
              <div class="item">
                <div class="title">贷款金额 (万)</div>
                <div class="input">
                  <input v-model="loan" placeholder="请输入贷款金额" class="text" @input="loanInput">
                </div>
              </div>
            </div>
            <div class="group" style="padding-top: 15px;">
              <div class="item" style="margin-right: 10px;">
                <div class="title">月供 (元)</div>
                <div class="input">
                  <input v-model="monthPay" placeholder="" class="text" disabled>
                </div>
              </div>
              <div class="item">
                <div class="title">总利息 (元)</div>
                <div class="input">
                  <input v-model="totalInterest" placeholder="" class="text" disabled>
                </div>
              </div>
            </div>
            <div class="type">
              <div class="item">
                <div class="title">贷款期限</div>
                <div class="cont" @click="yearStatus = true">
                  <div class="tt">{{ yearList[yearIndex].title }}</div>
                  <div class="ct">
                    <van-icon name="arrow" />
                  </div>
                </div>
                <van-popup v-model="yearStatus" round position="bottom">
                  <van-picker
                    v-if="yearStatus"
                    show-toolbar
                    :columns="yearList"
                    :default-index="yearIndex"
                    value-key="title"
                    confirm-button-text="完成"
                    @cancel="yearStatus = false"
                    @confirm="yearConfirm"
                  />
                </van-popup>
              </div>
              <div class="item">
                <div class="title">还款方式</div>
                <div class="cont" @click="payStauts = true">
                  <div class="tt">{{ payList[payIndex].title }}</div>
                  <div class="ct">
                    <van-icon name="arrow" />
                  </div>
                </div>
                <van-popup v-model="payStauts" round position="bottom">
                  <van-picker
                    v-if="payStauts"
                    show-toolbar
                    :columns="payList"
                    :default-index="payIndex"
                    value-key="title"
                    confirm-button-text="完成"
                    @cancel="payStauts = false"
                    @confirm="payConfirm"
                  />
                </van-popup>
              </div>
              <div class="item">
                <div class="title">贷款月利率</div>
                <div class="cont" @click="interestStauts = true">
                  <div class="tt">{{ interestList[interestIndex].title }}</div>
                  <div class="ct">
                    <van-icon name="arrow" />
                  </div>
                </div>
                <van-popup v-model="interestStauts" round position="bottom">
                  <van-picker
                    v-if="interestStauts"
                    show-toolbar
                    :columns="interestList"
                    :default-index="interestIndex"
                    value-key="title"
                    confirm-button-text="完成"
                    @cancel="interestStauts = false"
                    @confirm="interestConfirm"
                  />
                </van-popup>
              </div>
            </div>
            <div class="bottom">
              <div class="btn" @click="goPlan">生成还款计划表</div>
            </div>
          </div>
        </van-tab>
        <van-tab title="贷款流程">
          <div class="process">
            <div class="header">贷款流程</div>
            <div class="content">
              <div class="group">
                <div class="head">
                  STEP 1
                </div>
                <div class="cont">
                  <div class="title">
                    <span class="round" />
                    选定经理人
                  </div>
                </div>
              </div>
              <div class="group">
                <div class="head">
                  STEP 2
                </div>
                <div class="cont">
                  <div class="title">
                    <span class="round" />
                    订单跟踪
                  </div>
                  <div v-if="creditType == 2" class="des">初审 → 下户 → 面签 → 终审 → 进抵 → 放款</div>
                  <div v-else class="des">初审 → 面签 → 终审 → 放款</div>
                </div>
              </div>
              <div class="group">
                <div class="head">
                  STEP 3
                </div>
                <div class="cont">
                  <div class="title">
                    <span class="round" />
                    订单结束
                  </div>
                  <div class="des">贷款成功/贷款失败</div>
                </div>
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
      <div class="contact">
        <div class="btn" @click="goSelect">立即联系</div>
      </div>
    </template>
  </div>
</template>

<script>
import { getProductDetailBySalesman, matchConfirm } from '@/api/product'
import { getOssUrl } from '@/utils/oss'
import { setLocalUserInfo, getLocalUserInfo } from '@/utils/auth'
export default {
  name: 'ManagerDetail',
  data() {
    return {
      loading: false,
      scrollY: 0,
      list: [],
      swiperActive: 0,
      serviceFeeRateExplain: '', // 选中产品的须知1
      extraCharge: '', // 选中产品的须知2
      creditType: '', // 选中产品的贷款流程
      serviceList: [
        { title: '信息透明', id: 1 },
        { title: '收费透明', id: 2 },
        { title: '服务透明', id: 3 },
        { title: '隐私保障', id: 4 }
      ],
      serviceActive: 0,
      serviceNotice: [
        '拒绝信息不对称，保证产品无猫腻',
        '拒绝黑中介，收费标准公开、透明',
        '匹配专业经理人，保证服务进度全程可监控',
        '拒绝信息泄漏，坚决保护用户个人隐私'
      ],
      yearList: [],
      yearIndex: 0,
      yearStatus: false,
      payList: [
        { title: '等额本息', value: 1, index: 0 },
        { title: '等额本金', value: 2, index: 1 }
      ],
      payIndex: 0,
      payStauts: false,
      interestList: [],
      interestIndex: 0,
      interestStauts: false,
      monthPay: '',
      totalInterest: '',
      loan: '',
      eaUserName: '',
      params: {
        cityCode: '',
        orderId: '',
        userId: ''
      },
      isFixed: true,
      tabActive: 0,
      firstTabs: false, // 首次显示重绘
      keep: false // 是否已经缓存
    }
  },
  async mounted() {
    if (!this.keep) {
      await this.goReset()
    }
    this.keep = true
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'managerPlan') {
      this.scrollY = document.getElementById('manager-detail').scrollTop
    }
    next()
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name !== 'managerPlan') {
        vm.scrollY = 0
        vm.goReset()
      } else {
        console.log(vm.keep, '0000')
        if (vm.keep) {
          vm.tabActive = 2
          vm.$refs.swipe.swipeTo(vm.swiperActive)
        }
      }
    })
  },
  activated() {
    if (this.keep) {
      document.getElementById('manager-detail').scrollTop = this.scrollY
    }
  },
  methods: {
    tabScroll(e) {
      this.isFixed = e.isFixed
      if (e.isFixed && !this.firstTabs) {
        this.$refs.tabs.resize()
        this.firstTabs = true
      }
    },
    async goReset() {
      this.loading = true
      const { city } = getLocalUserInfo()
      const { orderId, userId, your } = this.$route.params
      this.params.cityCode = city.cityCode
      this.params.orderId = orderId
      this.params.userId = userId
      this.eaUserName = your
      await this.getList()
      this.loading = false
    },
    loanInput() {
      this.loan = this.loan.replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
      this.loan = this.loan.replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
      this.loan = this.loan.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
      this.loan = this.loan.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')// 只能输入两个小数
      if (this.loan.indexOf('.') < 0 && this.loan !== '') { // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        this.loan = parseFloat(this.loan)
      }
      const interestRateTo = this.interestList[this.interestIndex].value
      const creditLimitFrom = this.yearList[this.yearIndex].value
      const { monthPay, totalInterest } = this.getBxInterface(this.loan * 10000, interestRateTo / 100, creditLimitFrom)
      this.monthPay = monthPay.toFixed(2)
      this.totalInterest = totalInterest.toFixed(2)
    },
    goPlan() {
      const val = this.loan * 1
      const { loanTo, loanFrom } = this.list[this.swiperActive]
      if (val < loanFrom * 1 || val > loanTo * 1) return this.$toast('贷款金额不符合贷款额度范围！')
      const _this = this
      const planList = []
      const { yearList, yearIndex, interestList, interestIndex, payIndex } = _this
      const interestRateTo = interestList[interestIndex].value
      const creditLimitFrom = yearList[yearIndex].value
      let lastMoney = this.loan * 10000
      for (let i = 1; i <= creditLimitFrom; i++) {
        if (payIndex === 0) {
          const {
            monthMoney,
            monthInterest,
            monthPay,
            totalInterest,
            totalPay
          } = _this.getBxInterface(this.loan * 10000, interestRateTo / 100, creditLimitFrom, i)
          lastMoney = lastMoney - monthMoney > 0 ? lastMoney - monthMoney : 0.00
          planList.push({
            num: i,
            monthMoney: monthMoney.toFixed(2),
            monthInterest: monthInterest.toFixed(2),
            monthPay: monthPay.toFixed(2),
            totalInterest: totalInterest.toFixed(2),
            totalPay: totalPay.toFixed(2),
            loan: this.loan * 10000,
            interestRateTo: interestRateTo.toFixed(2),
            lastMoney: lastMoney.toFixed(2),
            payIndex
          })
        } else {
          const {
            monthMoney,
            monthInterest,
            monthPay,
            totalInterest,
            totalPay
          } = _this.getBjInterface(this.loan * 10000, interestRateTo / 100, creditLimitFrom, i)
          lastMoney = lastMoney - monthMoney > 0 ? lastMoney - monthMoney : 0.00
          planList.push({
            num: i,
            monthMoney: monthMoney.toFixed(2),
            monthInterest: monthInterest.toFixed(2),
            monthPay: monthPay.toFixed(2),
            totalInterest: totalInterest.toFixed(2),
            totalPay: totalPay.toFixed(2),
            loan: this.loan * 10000,
            interestRateTo: interestRateTo.toFixed(2),
            lastMoney: lastMoney.toFixed(2),
            payIndex
          })
        }
      }
      setLocalUserInfo({ planList })
      this.$router.push({ name: 'managerPlan' })
    },
    swiperChange(index) {
      const yearList = this.getYearList(this.list[index].creditLimitFrom, this.list[index].creditLimitTo)
      const interestList = this.getInterestList(this.list[index].interestRateFrom, this.list[index].interestRateTo)
      const { loanTo } = this.list[index]
      const interestRateTo = interestList[interestList.length - 1].value
      const creditLimitFrom = yearList[0].value
      const { monthPay, totalInterest } = this.getBxInterface(loanTo * 10000, interestRateTo / 100, creditLimitFrom)
      this.serviceFeeRateExplain = this.list[index].serviceFeeRateExplain
      this.extraCharge = this.list[index].extraCharge
      this.creditType = this.list[index].creditType
      this.yearList = yearList
      this.yearIndex = 0
      this.interestList = interestList
      this.interestIndex = interestList.length - 1
      this.payIndex = 0
      this.swiperActive = index
      this.loan = loanTo
      this.monthPay = monthPay.toFixed(2)
      this.totalInterest = totalInterest.toFixed(2)
    },
    yearConfirm(item) {
      const yearIndex = item.index
      this.yearIndex = yearIndex
      this.yearStatus = false
      const { payIndex, interestIndex } = this
      const interestRateTo = this.interestList[interestIndex].value
      const creditLimitFrom = this.yearList[yearIndex].value
      let monthPay = 0
      let totalInterest = 0
      if (payIndex === 0) {
        const res = this.getBxInterface(this.loan * 10000, interestRateTo / 100, creditLimitFrom)
        monthPay = res.monthPay.toFixed(2)
        totalInterest = res.totalInterest.toFixed(2)
      } else {
        const res = this.getBjInterface(this.loan * 10000, interestRateTo / 100, creditLimitFrom)
        monthPay = res.monthPay.toFixed(2)
        totalInterest = res.totalInterest.toFixed(2)
      }
      this.monthPay = monthPay
      this.totalInterest = totalInterest
    },
    payConfirm(item) {
      console.log(item, '999')
      const payIndex = item.index
      this.payIndex = payIndex
      this.payStauts = false
      const { yearIndex, interestIndex } = this
      const interestRateTo = this.interestList[interestIndex].value
      const creditLimitFrom = this.yearList[yearIndex].value
      let monthPay = 0
      let totalInterest = 0
      if (payIndex === 0) {
        const res = this.getBxInterface(this.loan * 10000, interestRateTo / 100, creditLimitFrom)
        monthPay = res.monthPay.toFixed(2)
        totalInterest = res.totalInterest.toFixed(2)
      } else {
        const res = this.getBjInterface(this.loan * 10000, interestRateTo / 100, creditLimitFrom)
        monthPay = res.monthPay.toFixed(2)
        totalInterest = res.totalInterest.toFixed(2)
      }
      this.monthPay = monthPay
      this.totalInterest = totalInterest
    },
    interestConfirm(item) {
      const interestIndex = item.index
      this.interestIndex = interestIndex
      this.interestStauts = false
      const { payIndex, yearIndex } = this
      const interestRateTo = this.interestList[interestIndex].value
      const creditLimitFrom = this.yearList[yearIndex].value
      let monthPay = 0
      let totalInterest = 0
      if (payIndex === 0) {
        const res = this.getBxInterface(this.loan * 10000, interestRateTo / 100, creditLimitFrom)
        monthPay = res.monthPay.toFixed(2)
        totalInterest = res.totalInterest.toFixed(2)
      } else {
        const res = this.getBjInterface(this.loan * 10000, interestRateTo / 100, creditLimitFrom)
        monthPay = res.monthPay.toFixed(2)
        totalInterest = res.totalInterest.toFixed(2)
      }
      this.monthPay = monthPay
      this.totalInterest = totalInterest
    },
    serviceChange(index) {
      this.serviceActive = index
    },
    getYearList(start, end) {
      const yearList = []
      let count = 0
      const finalStart = start
      while (start < end) {
        if (count === 0) {
          yearList.push({ title: `${start}个月`, value: start })
        } else if (count === 1) {
          if (start < 6) {
            start = 6
            yearList.push({ title: `${start}个月`, value: start })
          } else if (start > 6 && start < 12) {
            count++
            start = 12
          } else if (start >= 12) {
            count++
            start = 24
          }
        } else if (count === 2) {
          start += 6
        } else {
          if (start > finalStart) {
            yearList.push({ title: `${start}个月`, value: start })
          }
          start += 12
        }
        count++
      }
      const flag = yearList.some(item => {
        return item.value === end
      })
      if (!flag) {
        yearList.push({ title: `${end}个月`, value: end })
      }
      const list = yearList.map((item, index) => {
        item.index = index
        return item
      })
      return list
    },
    getInterestList(start, end) {
      const interestList = []
      while (start <= end) {
        interestList.push({ title: `${start}%`, value: start })
        start = ((start + 0.01).toFixed(2)) * 1
      }
      const list = interestList.map((item, index) => {
        item.index = index
        return list
      })
      return interestList
    },
    // total 贷款本金 rate 月利率 month 贷款期限 num 当前还款期数 类型(monthPay: 月供 monthInterest: 月利息 monthMoney: 每月还本金 totalInterest: 总利息 totalPay：还款总额)
    getBxInterface(total, rate, month, num) {
      const interest = rate / 12
      const monthPay = result = (total * interest * Math.pow(1 + interest, month)) / (Math.pow(1 + interest, month) - 1)
      const monthInterest = total * interest * (Math.pow(1 + interest, month) - Math.pow(1 + interest, (num - 1))) / (Math.pow(1 + interest, month) - 1)
      const monthMoney = total * interest * Math.pow(1 + interest, num - 1) / (Math.pow(1 + interest, month) - 1)
      const totalInterest = monthPay * month - total
      const totalPay = monthPay * month
      let result = {
        monthPay: monthPay,
        monthInterest: monthInterest,
        monthMoney: monthMoney,
        totalInterest: totalInterest,
        totalPay: totalPay
      }
      return result
    },
    // total 贷款本金 rate 月利率 month 贷款期限 类型(monthPay: 月供 monthInterest: 月利息 monthMoney: 每月还本金 totalInterest: 总利息 totalPay：还款总额)
    getBjInterface(total, rate, month, num = 1) {
      const interest = rate / 12
      const monthMoney = total / month
      const monthInterest = (total - (num === 1 ? 0 : monthMoney * (num - 1))) * interest // num 默认为1，第一期不需要减去月供金额
      const monthPay = monthMoney + monthInterest
      const totalPay = ((monthMoney + total * interest) + monthMoney * (1 + interest)) / 2 * month
      const totalInterest = totalPay - total
      const result = {
        monthPay: monthPay,
        monthInterest: monthInterest,
        monthMoney: monthMoney,
        totalInterest: totalInterest,
        totalPay: totalPay
      }
      return result
    },
    goSelect() {
      this.$dialog.confirm({
        title: '提示',
        message: '选定经理人之后不可更换，确定选择该业务员为经理人？',
        confirmButtonColor: '#FF7700'
      })
        .then(async() => {
          //
          const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
          await matchConfirm({ orderId: this.params.orderId, salesId: this.params.userId })
          loading.clear()
          this.$store.dispatch('user/setSelectMsgStatus', true)
          this.$router.replace({ name: 'chatRoom', params: { your: this.eaUserName }})
        })
        .catch(() => {
          // on cancel
        })
    },
    async getList() {
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      const res = await getProductDetailBySalesman(this.params)
      const list = res.datas
      const { creditLimitFrom, creditLimitTo, interestRateFrom, interestRateTo, loanTo, serviceFeeRateExplain, extraCharge, creditType } = list[0]
      for (let i = 0; i < list.length; i++) {
        list[i].funderLogo = await getOssUrl(list[i].funderLogo)
        list[i].managerHeadImgURL = await getOssUrl(list[i].managerHeadImgURL)
      }
      const yearList = this.getYearList(creditLimitFrom, creditLimitTo) || []
      const interestList = this.getInterestList(interestRateFrom, interestRateTo) || []
      this.list = list
      this.yearList = yearList
      this.yearIndex = 0
      this.payIndex = 0
      this.interestList = interestList
      this.loan = loanTo || ''
      this.serviceFeeRateExplain = serviceFeeRateExplain
      this.extraCharge = extraCharge
      this.creditType = creditType || '2'
      this.interestIndex = interestList.length - 1
      const { monthPay, totalInterest } = this.getBxInterface(this.loan * 10000, interestRateTo / 100, creditLimitFrom)
      this.monthPay = monthPay.toFixed(2)
      this.totalInterest = totalInterest.toFixed(2)
      loading.clear()
    }
  }
}
</script>

<style lang="scss" scoped>
.manager-detail {
  height: 100%;
  overflow-y: auto;
  padding: 16px;
  box-sizing: border-box;
  background: #fcfcfc;
  .info {
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .name {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #2e3135;
        .avatar {
          width: 32px;
          height: 32px;
          margin-right: 10px;
          border-radius: 16px;
        }
      }
      .des {
        font-size: 14px;
        color: #2e3135;
        .num {
          padding-right: 5px;
          color: #ff7700;
        }
      }
    }
    .list::v-deep {
      margin: 0 -16px;
      .my-swipe {
        padding-bottom: 40px;
      }
    }
    .notice {
      .head {
        padding: 10px 0;
        font-size: 12px;
        color: #F86767;
      }
      .cont {
        line-height: 18px;
        font-size: 12px;
        color: #666666
      }
    }
  }
  .service {
    position: relative;
    top: 40px;
    .header {
      padding: 16px 0;
      font-size: 16px;
      color: #2E3135;
      font-weight: bold;
    }
    .content {
      .list {
        display: flex;
        align-items: center;
        .item {
          flex: 1;
          padding: 10px 0;
          text-align: center;
          &.active {
            background: #eff1f3;
          }
          .icon {
            .img {
              width: 45px;
              height: 45px;
            }
          }
          .title {
            padding-top: 10px;
            font-size: 12px;
            color:#666666;
          }
        }
      }
      .notice {
        padding: 9px 18px;
        font-size: 14px;
        color: #32465A;
        background: #eff1f3;
      }
    }
  }
  .countor {
    position: relative;
    top: 40px;
    padding-top: 15px;
    .header {
      padding: 16px 0;
      font-size: 16px;
      color: #2E3135;
      font-weight: bold;
    }
    .group {
      display: flex;
      .item {
        flex: 1;
        .title {
          padding: 10px 0;
          font-size: 12px;
          color: #999999;
        }
        .input {
          .text {
            width: 100%;
            padding: 0 8px;
            font-size: 18px;
            color: #2E3135;
            height: 44px;
            line-height: 44px;
            border-radius: 8px;
            box-sizing: border-box;
            border: 0;
            background: #F5F5F7;
          }
        }
      }
    }
    .type {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 20px;
      .item::v-deep {
        width: 105px;
        height: 62px;
        padding: 10px;
        box-sizing: border-box;
        border: 1px solid #E6E6E6;
        border-radius: 8px;
        .van-picker__confirm {
          color: #FF7700;
        }
        .van-picker-column__item--selected {
          color: #FF7700;
          background: #fef4e8;
        }
        .title {
          font-size: 12px;
          color: #999999;
        }
        .cont {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-top: 12px;
          .tt {
            font-size: 14px;
            color: #2E3135;
          }
          .ct {
            .icon {
              width: 6px;
              height: 10px;
            }
          }
        }
      }
    }
    .bottom {
      padding: 24px;
      .btn {
        width: 100%;
        height: 47px;
        line-height: 47px;
        font-size: 14px;
        color: #FF7700;
        text-align: center;
        border-radius: 47px;
        border: 1px solid #FF7700;
      }
    }
  }
  .process {
    position: relative;
    top: 60px;
    .header {
      padding: 20px 0;
      font-size: 16px;
      color: #2E3135;
      font-weight: bold;
    }
    .content {
      padding: 20px 50px;
      background: #FFFFFF;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.06);
      border-radius: 8px;
      .group {
        position: relative;
        padding-top: 55px;
        padding-bottom: 20px;
        .head {
          position: absolute;
          top: 0;
          left: -65px;
          width: 110px;
          height: 48px;
          font-size: 14px;
          color: #FFFFFF;
          padding: 15px 0 0 30px;
          box-sizing: border-box;
          background-size: 100%;
          background-image: url('~@/assets/match/step@2x.png')
        }
        .cont {
          .title {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: bold;
            color: #2E3135;
            .round {
              width: 7px;
              height: 7px;
              margin-right: 10px;
              border-radius: 7px;
              background: #FFB367;
            }
          }
          .des {
            padding-top: 20px;
            padding-left: 17px;
            font-size: 14px;
            color: #666666;
            line-height: 24px;
          }
        }
      }
    }
  }
  .contact {
    padding: 80px 24px 200px 24px;
    .btn {
      height: 47px;
      line-height: 47px;
      text-align: center;
      font-size: 16px;
      color: #FFFFFF;
      background: linear-gradient(136deg, #FFB367 0%, #FF7700 100%);
      border-radius: 24px;
    }
  }
}
.swiper-item {
  position: relative;
  padding: 200px 16px 16px 16px;
  margin: 0 32px;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  .top {
    position: absolute;
    top: 0;
    left: -25px;
    width: 361px;
    height: 196px;
    padding: 39px;
    box-sizing: border-box;
    background-size: 100%;
    background-image: url('~@/assets/match/card@2x.png');
    .header {
      display: flex;
      align-items: center;
      .logo {
        .img {
          width: 50px;
          height: 50px;
        }
      }
      .des {
        flex: 1;
        padding-left: 10px;
        .name {
          font-size: 16px;
          font-weight: bold;
          color: #2E3135;
        }
        .type {
          padding-top: 10px;
          .it {
            padding: 3px 10px;
            margin-right: 5px;
            font-size: 12px;
            color: #18C47C;
            background: #DCF6EB;
            border-radius: 4px;
          }
        }
      }
    }
    .content {
      display: flex;
      padding-top: 25px;
      .group {
        flex: 1;
        text-align: center;
        .name {
          font-size: 12px;
          color: #999999;
        }
        .des {
          padding-top: 10px;
          font-size: 12px;
          color: #2E3135;
          .num {
            font-size: 18px;
            font-weight: bold;
            color: #FF7700;
          }
        }
      }
    }
  }
  .bottom {
    .group {
      display: flex;
      font-size: 14px;
      color: #32465A;
      margin-bottom: 16px;
      .title {
        width: 65px;
        flex: none;
        color: #91A0B4;
      }
      .table {
        flex: 1;
        border-radius: 4px;
        border: 1px solid #CCCCCC;
        .tr {
          display: flex;
          line-height: 30px;
          border-bottom: 1px solid #CCCCCC;
          &:last-child{
            border: 0;
          }
          .td {
            flex: 1;
            text-align: center;
            border-right: 1px solid #CCCCCC;
            &:last-child {
              border: 0;
            }
          }
        }
      }
    }
  }
}

.manager-detail::v-deep {
  .service {
    padding-top: 20px;
  }
  .van-tabs {
    position: relative;
    top: -40px;
  }
  .van-tabs__wrap {
    display: none;
    border-bottom: 1px solid #e5e5e5;
  }
  .tab-show {
    .van-tabs__wrap {
      display: block;
    }
  }
}
</style>
